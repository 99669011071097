<template>
  <div class="protocol">
    <div class="protocol-content" id="protocolContent">
      <div v-if="pageData.pdfLoaded" class="pdf-content">
        <PdfView :pdfUrl="pdfUrl" />
      </div>
      <div v-else style="text-align:center;margin:30px 0">
        <van-loading type="spinner" color="#1989fa" size="30px">文件加载中...</van-loading>
      </div>
    </div>
    <div class="btn">
      <div class="fixed" :style="fixedStyle">
        <van-button v-if="article && article.isBottom" :disabled="pageData.disabled" :loading="pageData.loading" type="info" color="#26cdb3" block @click="confirm">{{ pageData.disabled ? "您已确认" : "确认并同意" }}</van-button>
        <van-button v-else type="info" color="#ccc" block>请下滑浏览完本条款后点击【确认并同意】</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import Util from "@/utils/common";
import { request } from "@/api/service";
import { request as requestExport } from "@/api/serviceExport";
import PdfView from "@/components/Pdf";
export default {
  name: "CustomerProtocol",
  components: { PdfView },
  data() {
    return {
      pageData: {
        disabled: false,
        loading: false,
        pdfLoaded: false
      },
      orderId: null,
      fixedStyle: "bottom: 0px;",
      article: {
        isBottom: false
      },
      pdfUrl: null
    };
  },
  created() {
    const { id } = this.$route.query;
    this.orderId = id;
    this.fixedStyle = Util.isIOS() && !Util.isIPhoneX() ? "bottom: 32px;" : "bottom: 0px;";
    this.getStatus();
    this.initPdf();
  },
  mounted() {
    this.initEvent("protocolContent");
  },
  methods: {
    initEvent(elementId) {
      let addEvent = (function() {
        if (window.addEventListener) {
          return function(elm, type, handle) {
            elm.addEventListener(type, handle, false);
          };
        }
        if (window.attachEvent) {
          return function(elm, type, handle) {
            elm.attachEvent("on" + type, handle);
          };
        }
      })();
      let element = document.getElementById(elementId);
      element &&
        addEvent(element, "scroll", () => {
          let scrollHeight = element.scrollHeight;
          let scrollTop = element.scrollTop;
          let height = element.offsetHeight;
          if (scrollTop + height >= scrollHeight - 1) {
            this.finish();
          }
        });
    },
    initPdf() {
      requestExport({ url: `/afis-carservice/order/contract/preview/${this.orderId}`, method: "post" })
        .then(file => {
          let pdfUrl = null;
          if (window.createObjectURL) {
            // basic
            pdfUrl = window.createObjectURL(file);
          } else if (window.webkitURL) {
            // webkit or chrome
            pdfUrl = window.webkitURL.createObjectURL(file);
          } else if (window.URL) {
            // mozilla(firefox)
            pdfUrl = window.URL.createObjectURL(file);
          }
          this.pdfUrl = pdfUrl;
          this.pageData.pdfLoaded = true;
        })
        .catch(error => {
          this.$toast(error.message);
        });
    },
    finish() {
      this.$set(this.article, "isBottom", true);
    },
    getStatus() {
      request({
        url: `afis-carservice/order/status/${this.orderId}`,
        method: "post",
        data: {}
      })
        .then(res => {
          if (res && res.orderStatus === "5") {
            return false;
          }
          this.pageData.disabled = true;
        })
        .catch(error => {});
    },
    confirm() {
      this.pageData.loading = true;
      request({
        url: `afis-carservice/order/client/confirm/${this.orderId}`,
        method: "post",
        data: {}
      })
        .then(res => {
          this.pageData.loading = false;
          this.pageData.disabled = true;
          this.$toast("确认成功");
        })
        .catch(error => {
          this.pageData.loading = false;
        });
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/style/var.scss";
.protocol {
  font-size: 18px;
  height: 100%;
  overflow: hidden;
  &-content {
    position: relative;
    height: calc(100vh - 44px); //底部44px 顶部90px
    overflow: scroll;
  }
  .btn {
    position: relative;
    height: 44px;
    .fixed {
      position: fixed;
      background: #fff;
      padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
      padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
      left: 0px;
      right: 0px;
    }
  }
}
</style>
