<template>
  <div v-if="pageData.pdfLoading" class="pdf-content">
    <pdf v-for="i in numPages" :key="i" :src="pdfSrc" :page="i"></pdf>
  </div>
</template>
<script>
import pdf from "./pdf";
export default {
  name: "PdfView",
  components: { pdf },
  props: {
    pdfUrl: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      pageData: {
        pdfLoading: false
      },
      pdfSrc: null,
      numPages: 0
    };
  },
  mounted() {
    if (!this.pdfUrl) {
      this.$toast("合同链接不能为空");
      return false;
    }
    const loadingTask = pdf.createLoadingTask({
      url: this.pdfUrl,
      // 引入pdf.js字体，templ
      cMapUrl: "/pdf/web/cmaps/",
      cMapPacked: true
    });
    this.pdfSrc = loadingTask;
    this.pdfSrc.promise
      .then(pdf => {
        this.pageData.pdfLoading = true;
        this.numPages = pdf.numPages;
      })
      .catch(error => {
        this.$toast(error.message);
      });
  }
};
</script>
<style scoped></style>
